@font-face {
  font-family: "PerfectDOS";
  src: local("PerfectDOS"),
    url(./assets/fonts/Perfect\ DOS\ VGA\ 437.ttf) format("truetype");
}

:root {
  --pink: #f37298;
  --deep-blue: #0d294e;
  --grey: #495057;
  --white: #ffffff;
  --grey00: #aaabaa;

  /* othertheme */
  --dos-yellow: #ffff50;
  --dos-ligth-red: #ff5750;
  --dos-green: #00a800;
  --dos-black: #000000;

  --dos2grey: #a8a8a8;
  --dos2cyan: #10abab;
  --dos2red: #ac0404;
  --dos2cyan: #00a8a8;
  --dos2pink: #a800a8;

  /* Themes template*/
  --title-bar: #ac0404;
  --menu-bar: #aaabaa;
  --menu-font: #000000;
  --menu-hl: #ffffff;
  --name-font: #2e3138;
  --theme-font: #ffffff;
  --main-card: #aaabaa;
  --dev-desc: #ac0404;
  --retro-btn: #00a8a8;
  --shadows: #2e3138;
  --card-shadows: #2e3138;
  --tuifield: #0000a8;
  --scroll-back: #0000a8;
  --img-shadow: #2e3138;
  --background: #000000c5;
  --borders: #ffffff;
  --tuifield-fnb: #ffffff;
  --skill-stroke: #000000;

  /* Themes Default*/
  --def-title-bar: #ac0404;
  --def-menu-bar: #aaabaa;
  --def-menu-font: #000000;
  --def-menu-hl: #ffffff;
  --def-name-font: #2e3138;
  --def-theme-font: #ffffff;
  --def-main-card: #aaabaa;
  --def-dev-desc: #ac0404;
  --def-retro-btn: #00a8a8;
  --def-shadows: #2e3138;
  --def-card-shadows: #2e3138;
  --def-tuifield: #0000a8;
  --def-scroll-back: #0000a8;
  --def-img-shadow: #2e3138;
  --def-background: #000000c5;
  --def-borders: #ffffff;
  --def-tuifield-fnb: #ffffff;
  --def-skill-stroke: #000000;
}

* {
  background-color: #121212;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: "PerfectDOS", cursive;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
  background-image: radial-gradient(var(--scroll-back) 1px, transparent 0);
  background-size: 3.5px 3.5px;
  background-position: -1px -1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: var(--menu-bar);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--title-bar);
}

body {
  background-color: #020202;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

legend {
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
}

legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.introname {
  font-family: "Press Start 2P", cursive;
  margin: 0 0 0 -12px;
  position: absolute;
  top: 30%;
  left: 50%;
  -ms-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  color: var(--pink);
  width: 80%;
  font-size: 4rem;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.introname2 {
  font-family: "Audiowide", cursive;
}

.f2-theme {
  color: var(--menu-hl);
  cursor: pointer;
  align-self: flex-start;
  padding: 16px;
}

.f2-theme::before {
  content: "F2:";
}

.f2-theme-spa {
  color: var(--menu-hl);
  cursor: pointer;
  align-self: flex-start;
  padding: 16px;
}

.f2-theme-spa::before {
  content: "F2:";
}

.my-name {
  color: var(--name-font);
  margin-bottom: -15px;
}

.devfont {
  margin: 0;
  position: absolute;
  top: 65%;
  left: 50%;
  -ms-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  color: white;
  width: 80%;
  font-size: 2.5rem;
  text-align: center;
  opacity: 0.85;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "PerfectDOS", cursive;
}

.nav-bar {
  height: 50px;
  background-color: var(--menubar);
  background: linear-gradient(
    180deg,
    var(--title-bar) 50%,
    var(--menu-bar) 50%
  );
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-right: -0.5px;
  position: static;
  top: 0;
  z-index: -1;
  align-items: flex-end;
}

.nav-btns {
  list-style: none;
  display: flex;
  width: fit-content;
  z-index: 10;
  width: 100%;
  height: 55%;
}

.nav-btns li {
  margin: 0 10px;
  padding: 2px;
}

.nav-btns li span {
  text-decoration: none;
  flex: 1;
  text-align: center;
  font-weight: 100;
  color: black;
  transition: all 0.2s ease;
  padding-bottom: 27px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav-btns li :hover {
  color: var(--menu-hl);
}

.nav-btns li span h1 {
  font-size: 20px;
  border-width: 0px 5px 0px 5px;
  border-style: solid;
  border-color: transparent;
}

.nav-on {
  text-align: center;
}

.nav-on span h1 {
  font-size: 20px;
  border-width: 0px 5px 0px 5px;
  border-style: solid;
  border-color: black;
  color: var(--menu-hl);
  background-color: black;
  background: linear-gradient(180deg, black 85%, transparent 85%);
}

.hotkey::first-letter {
  color: var(--menu-hl);
}

.burger-btn {
  color: var(--img-shadow);
  margin: -8px 1px 0px -4px;
  align-self: flex-start;
}

.close-btn {
  align-self: center;
  padding: 1px;
  color: var(--img-shadow);
  height: 30px;
  width: 30px;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
  cursor: pointer;
  z-index: 10;
  margin: -8px -8px 0px 0px;
  align-self: flex-start;
}

.title-bar-but-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.close-btn:hover {
  color: var(--menu-bar);
}

.close-btn path :hover {
  stroke: black;
  stroke-width: 30px;
}

.portfolio-card {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 90vh;
  background-color: var(--background);
  backdrop-filter: blur(5px);
  box-shadow: 3px 3px 60px rgba(0, 0, 0, 0.24);
  border: 2px solid var(--borders);
  transition: 1s;
}

.portfolio-section {
  position: absolute;
  top: calc(50% + 25px);
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 89.75vw;
  height: calc(90vh - 54px);
  overflow-y: auto;
  z-index: 100;
}

.about-card {
  align-items:flex-start;
  margin: 7vh auto;
  padding: 32px 36px 32px 36px;
  width: 82vw;
  background-color: var(--main-card);
  background-image: radial-gradient(black 1px, transparent 0);
  display: flex;
  justify-content: space-between;
  border: 2px solid var(--borders);
  box-shadow: 6px 6px var(--card-shadows);
  transition: 1s;
}

.tui-fieldset {
  border: 6px var(--tuifield-fnb) double;
  padding: 12px;
  background-color: inherit;
  margin-bottom: 0px;
  display: block;
  min-inline-size: min-content;
}

.tui-fieldset legend {
  color: var(--tuifield-fnb);
}

.contact-input {
  color: var(--tuifield-fnb) !important;
  border-color: var(--tuifield-fnb) !important;
}

.contact-input fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--tuifield-fnb) !important;
}

.contact-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  list-style: none;
}

.contact-name {
  margin-right: 10px !important;
}

.contact-mail {
  margin-left: 10px !important;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.skills {
  margin-top: 5vh;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.headers {
  margin: 1em 0px 0px 1em;
  font-weight: bold;
  color: var(--menu-hl);
}

.projects {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.my-panel {
  padding: 0px 6px 7px 6px;
  margin: 36px;
}

.panelout {
  background-color: var(--tuifield) !important;
  float: left;
  min-width: fit-content;
  transition: 1s;
}

.profile-description {
  color: var(--theme-font);
  padding-left: 20px;
  transition: 1s;
}

.profile-dev {
  padding-left: 20px;
  transition: 1s;
  font-size: 25px;
  color: var(--dev-desc);
}

.about-me {
  text-align: right;
  vertical-align: top;
  padding-left: 50px;
  line-height: 1.8;
  transition: 1s;
}

.about-me h1 {
  font-size: 34px;
}

.list-inline-item {
  display: inline-block;
  margin: 0px 20px 0px 20px;
}

.skill-icons {
  margin: 20px 0 0 0;
  font-size: 3rem;
}

.logo-btn {
  color: var(--tuifield-fnb);
  opacity: 1;
}

.logo-btn:hover {
  color: var(--dev-desc);
  opacity: 0.9;
}

.tui-button {
  display: inline-block;
  outline: 0;
  padding: 1px 10px;
  background-color: var(--retro-btn);
  color: black;
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 1ch 1ch var(--shadows);
  border-radius: 0px;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.tui-button-w {
  box-shadow: 1ch 1ch white;
}

.tui-button:active {
  background-color: rgb(255, 145, 0);
  transform: translate(0.5ch, 0.5ch);
  box-shadow: 0.5ch 0.5ch #a8a8a8;
}

.tui-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  color: var(--tuifield-fnb);
  padding-left: 0ch;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.tui-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.tui-radio {
  text-align: right;
  display: block;
  position: relative;
  cursor: pointer;
  color: white;
  padding-left: 0ch;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.tui-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

input,
select,
textarea {
  width: 200px;
}

.submit-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
}

.scl-container {
  margin: 30px 10px 0px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 3vw;
  align-items: flex-end;
  padding-bottom: 20px;
}

#radio {
  text-align: right;
}

.tui-checkbox input:checked ~ span::after {
  content: "[X]";
  margin-right: 10px;
  color: cyan;
}

.tui-radio input:checked ~ span::after {
  content: "(*)";
  margin-right: 0px;
  margin-left: 10px;
  color: cyan;
}

.tui-radio input:not(checked) ~ span::after {
  content: "( )";
  margin-right: 0px;
  margin-left: 10px;
}

.tui-checkbox input:not(checked) ~ span::after {
  content: "[ ]";
  margin-right: 10px;
}

.Typewriter__cursor {
  position: relative;
  color: var(--dev-desc);
  top: -2px;
}

.typewritter {
  position: relative;
}

.typewritter::after {
  content: "■";
  position: absolute;
  right: 0;
  color: white;
  background-color: var(--grey00);
  width: 100%;
  animation: typing 3s steps(54) alternate infinite, line 1s infinite;
}

.card-view {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-img {
  object-fit: cover;
}

.crt-monitor {
  background-color: transparent;
  color: #fff;
  border-radius: 2rem;
  border: 1rem solid;
  border-bottom-color: #121212;
  border-left-color: #080808;
  border-right-color: #080808;
  border-top-color: #020202;
  box-shadow: inset 0 0 18rem black, inset 0 0 3rem black, 0 0 10rem black;
  height: 96%;
  position: absolute;
  top: 2%;
  left: 2%;
  width: 96%;
  pointer-events: none;
}

.crt-piece {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.crt-fx {
  -webkit-animation: crt-fx 10ms infinite;
  animation: crt-fx 10ms infinite;
  background-color: #031d10;
  overflow: hidden;
  position: absolute;
  padding: 3rem 2rem;
  pointer-events: auto;
  text-shadow: 0rem 0.3rem 1rem #0c7b46;
  z-index: -1;
}

.footnote-container {
  height: 100%;
}

.footnote {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  justify-content: center;
}

.footnote-section {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

@keyframes typing {
  to {
    width: 0%;
  }
}

@keyframes line {
  50% {
    color: transparent;
  }
}

.prj-container {
  margin: 40px 0px 40px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 3vh 3vh;
}

.prj-card {
  transform: translateZ(0);
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--main-card);
  border: 2px solid var(--borders);
  box-shadow: 6px 6px var(--card-shadows);
  /* transition: all 0.2s ease, transform 1s ease; */
  /* transition: all 0.2s ease; */
  transition: transform 0.3s ease;
  color: var(--theme-font);
  /* cursor: pointer; */
  width: 400px;
  height: 450px;
  /* transition: 1s; */
}

.prj-card:hover {
  /* position: relative; */
  transform: scale(1.3);
  margin: 0 auto !important;
  box-shadow: 0px 0px;
  /* transition: all 1s ease; */ /* zoom in */
 /*  cursor: pointer;*/
  z-index: 2000;
}

.prj-card h2 {
  color: var(--menu-hl);
  text-shadow: 1px 1px 2px black;
  position: absolute;
  padding: 1vh 0 0vw 1vw;
  z-index: 2;
}

.prj-card p {
  position: relative;
  font-size: 14px;
  color: var(--theme-font);
  text-align: center;
  margin: 0;
  margin-top: 1em;
  padding: 0vh 2vh 0vh 2vh;
}

.prj-card img {
  max-width: 100%;
  object-fit: cover;
}

.prj-img-container {
  max-width: 100%;
  object-fit: cover;
}

.prj-img {
  max-width: 100%;
  width: 400px;
  height: 5%;
  object-fit: cover;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
  position: absolute;
  z-index: 100;
}

.prj-row-2 {
  grid-column: 1 / 2;
}

.prj-stack-text {
  grid-column: 1 / 2;
  padding: 0 !important;
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 1px !important;
  align-self: flex-end !important;
  z-index: 2000;
  text-shadow: 1px 1px 0 var(--skill-stroke), -1px 1px 0 var(--skill-stroke),
    1px -1px 0 var(--skill-stroke), -1px -1px 0 var(--skill-stroke),
    0px 1px 0 var(--skill-stroke), 0px -1px 0 var(--skill-stroke),
    -1px 0px 0 var(--skill-stroke), 1px 0px 0 var(--skill-stroke),
    2px 2px 0 var(--skill-stroke), -2px 2px 0 var(--skill-stroke),
    2px -2px 0 var(--skill-stroke), -2px -2px 0 var(--skill-stroke),
    0px 2px 0 var(--skill-stroke), 0px -2px 0 var(--skill-stroke),
    -2px 0px 0 var(--skill-stroke), 2px 0px 0 var(--skill-stroke),
    1px 2px 0 var(--skill-stroke), -1px 2px 0 var(--skill-stroke),
    1px -2px 0 var(--skill-stroke), -1px -2px 0 var(--skill-stroke),
    2px 1px 0 var(--skill-stroke), -2px 1px 0 var(--skill-stroke),
    2px -1px 0 var(--skill-stroke), -2px -1px 0 var(--skill-stroke);
}

.prj-stack-text:before {
  content: "●● ";
}
.prj-stack-text:after {
  content: " ●●";
}

.scrollable-section {
  scroll-margin: 10px;
  min-height: 80vh;
  position: relative;
}

.caret-blinking {
  animation: fadeIn 0.5s forwards, blinkingCaret 0.75s linear infinite alternate;
  visibility: hidden;
}

.pulsate {
  -webkit-animation: pulsate 9s ease-in-out 2s infinite both;
  animation: pulsate 9s ease-in-out 2s infinite both;
}

.ln-loza {
  font-size: 12px;
  text-align: center;
}

.config-container {
  width: 80vh;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.retro-container {
  display: grid;
  color: #fff;
  justify-content: center;
  border: 6px white double;
  background-color: inherit;
  margin: 12px;
  grid-template-columns: 1fr auto 1fr;
}

.retro-container-box-1 {
  padding: 12px;
  /*   border: 1px solid white; */
  grid-column: 1 / 2;
}

.retro-container-box-2 {
  padding: 12px;
  grid-column: 3 / 3;
  /*   border: 1px solid white; */
}

.retro-container-box-3 {
  padding: 12px;
  /*   border: 1px solid white; */
  grid-column: 1 / 2;
}

.retro-container-box-4 {
  padding: 12px;
  grid-column: 3 / 3;
  /*   border: 1px solid white; */
}

.retro-container-box-5 {
  padding: 12px;
  /*   border: 1px solid white; */
  grid-column: 1 / 2;
}

.retro-container-box-6 {
  padding: 12px;
  grid-column: 3 / 3;
  /*   border: 1px solid white; */
}

.retro-container-box-colons {
  padding: 12px;
  grid-column: 2 / 2;
  /*   border: 1px solid white; */
}

.retro-container-box-launch {
  padding: 12px;
  text-align: center;
  border-top: 1px solid white;
  grid-column: 1 / -1;
}

/* Animations: */
@-webkit-keyframes pulsate {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  1.5% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  3% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  5.5% {
    -webkit-transform: scale(0.65);
    transform: scale(0.65);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  8% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes pulsate {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  1.5% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  3% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  5.5% {
    -webkit-transform: scale(0.65);
    transform: scale(0.65);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  8% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.from-shadow {
  -webkit-animation: from-shadow 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: from-shadow 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@-webkit-keyframes from-shadow {
  0% {
    -webkit-box-shadow: 0 0 var(--img-shadow);
    box-shadow: 0 0 var(--img-shadow);
    -webkit-transform: translateX(6px) translateY(6px);
    transform: translateX(6px) translateY(6px);
  }
  100% {
    -webkit-box-shadow: 6px 6px var(--img-shadow);
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes from-shadow {
  0% {
    -webkit-box-shadow: 0 0 var(--img-shadow) lack;
    box-shadow: 0 0 var(--img-shadow);
    -webkit-transform: translateX(6px) translateY(6px);
    transform: translateX(6px) translateY(6px);
  }
  100% {
    -webkit-box-shadow: 6px 6px var(--img-shadow);
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

.from-shadow-white {
  -webkit-animation: from-shadow-white 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: from-shadow-white 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@-webkit-keyframes from-shadow-white {
  0% {
    -webkit-box-shadow: 0 0 var(--card-shadows);
    box-shadow: 0 0 var(--card-shadows);
    -webkit-transform: translateX(6px) translateY(6px);
    transform: translateX(6px) translateY(6px);
  }
  100% {
    -webkit-box-shadow: 6px 6px var(--card-shadows);
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes from-shadow-white {
  0% {
    -webkit-box-shadow: 0 0 var(--card-shadows);
    box-shadow: 0 0 var(--card-shadows);
    -webkit-transform: translateX(6px) translateY(6px);
    transform: translateX(6px) translateY(6px);
  }
  100% {
    -webkit-box-shadow: 6px 6px var(--card-shadows);
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

.from-shadow-white-but {
  -webkit-animation: from-shadow-white-but 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: from-shadow-white-but 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@-webkit-keyframes from-shadow-white-but {
  0% {
    -webkit-box-shadow: 0 0 var(--shadows);
    box-shadow: 0 0 var(--shadows);
    -webkit-transform: translateX(0ch) translateY(0ch);
    transform: translateX(0ch) translateY(0ch);
  }
  100% {
    -webkit-box-shadow: 1ch 1ch var(--shadows);
    -webkit-transform: translateX(1ch) translateY(1ch);
    transform: translateX(1ch) translateY(1ch);
  }
}

@keyframes from-shadow-white-but {
  0% {
    -webkit-box-shadow: 0 0 var(--shadows);
    box-shadow: 0 0 var(--shadows);
    -webkit-transform: translateX(0ch) translateY(0ch);
    transform: translateX(0ch) translateY(0ch);
  }
  100% {
    -webkit-box-shadow: 1ch 1ch var(--shadows);
    -webkit-transform: translateX(1ch) translateY(1ch);
    transform: translateX(1ch) translateY(1ch);
  }
}

/* .from-center {
  -webkit-animation: from-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: from-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

@-webkit-keyframes from-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0, 0.7);
    transform: scale(0, 0.7);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes from-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0, 0.7);
    transform: scale(0, 0.7);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes crt-fx {
  0% {
    opacity: 0.75;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.hideMe {
  -webkit-animation: hideAnim 0s ease-in 12s forwards;
  animation: hideAnim 0s ease-in 12s forwards;
}

@keyframes hideAnim {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes hideAnim {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

@keyframes blinkingCaret {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.processor-fade-in {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.memory-fade-in {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.loading-ap-fade-in {
  animation: 6s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.loading-pf-fade-in {
  animation: 7s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.launch-fade-in {
  animation: 9.5s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.caret-fade-in {
  animation: fadeIn 12.5s forwards, blinkingCaret 0.5s linear infinite alternate,
    hideAnim 0s ease-in 14.5s forwards;

  visibility: hidden;
}

.pf-config {
  animation: 15s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.load-type {
  display: inline-flex;
}

.load-type span {
  word-break: break-all;
  height: 1.2em;
  width: 0%;
  overflow: hidden;
  animation: lt 3s linear 1 7s both;
}

.load-type span:before {
  content: " ";
  display: inline-block;
}

@keyframes lt {
  90% {
    width: 70%;
  }
  95%,
  100% {
    width: 100%;
  }
}

.scanlines {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.6)
  );
  background-size: 100% 0.3rem;
  border-radius: 2rem;
  position: absolute;
}

.glow {
  animation: crt-glow 60s infinite;
  background: radial-gradient(
    circle at center,
    #1bd459 0%,
    rgba(27, 212, 89, 0.88) 58%,
    rgba(21, 235, 92, 0.57) 80%,
    rgba(19, 94, 29, 0.27) 93%,
    rgba(10, 23, 12, 0) 100%
  );
  opacity: 0.15;
  pointer-events: none;
  position: fixed;
}

.launch-glow {
  cursor: pointer;
  text-align: center;
  -webkit-animation: launch-glow 4s ease-in-out infinite alternate;
  animation: launch-glow 4s ease-in-out infinite alternate;
}

@-webkit-keyframes launch-glow {
  from {
    text-shadow: 1 0 0px #fff, 1 0 1px #fff, 1 0 2px #94e49fe0,
      1 0 3px #94e49fe0, 0 0 4px #94e49fe0, 0 0 5px #94e49fe0, 0 0 5px #94e49fe0;
  }
  to {
    text-shadow: 0 0 1px #fff, 0 0 2px #70ff83e0, 0 0 3px #70ff83e0,
      0 0 4px #70ff83e0, 0 0 5px #70ff83e0, 0 0 5px #70ff83e0, 0 0 6px #70ff83e0;
  }
}

@keyframes launch-glow {
  from {
    text-shadow: 1 0 0px #fff, 1 0 1px #fff, 1 0 2px #94e49fe0,
      1 0 3px #94e49fe0, 0 0 4px #94e49fe0, 0 0 5px #94e49fe0, 0 0 5px #94e49fe0;
  }
  to {
    text-shadow: 0 0 1px #fff, 0 0 2px #70ff83e0, 0 0 3px #70ff83e0,
      0 0 4px #70ff83e0, 0 0 5px #70ff83e0, 0 0 5px #70ff83e0, 0 0 6px #70ff83e0;
  }
}

.hide-menu-btns-all-true {
  display: none !important;
}

.hide-hmb {
  display: none !important;
}

@media only screen and (max-width: 826px) {
  .config-container {
    width: 80vw;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .ln-loza {
    font-size: 10px;
    text-align: center;
  }

  .f2-theme::before {
    content: "● Toggle ";
  }

  .f2-theme::after {
    content: " ●";
  }

  .f2-theme {
    letter-spacing: 0.2rem;
    font-size: 1.5em;
    text-align: center;
    padding: 2.5em 0em 0em 0em;
  }

  .f2-theme-spa::before {
    content: "● Cambiar ";
  }

  .f2-theme-spa::after {
    content: " ●";
  }

  .f2-theme-spa {
    letter-spacing: 0.2rem;
    font-size: 1.5em;
    text-align: center;
    padding: 2.5em 0em 0em 0em;
  }

  .hide-menu-btns-true {
    display: none !important;
  }

  .hide-menu-btns-false {
    display: flex !important;
  }

  .show-opt-false {
    display: none;
  }

  .title-bar-but-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .about-me {
    text-align: center;
    margin-top: 10px;
    padding-left: 1em;
  }

  .menu-open-true {
    height: 250px;
    background: linear-gradient(
      180deg,
      var(--title-bar) 10%,
      var(--menu-bar) 10%
    );
    display: block;
  }

  .menu-open-true li {
    padding: 0px;
    margin: 0px;
  }

  .menu-open-true h1 {
    letter-spacing: 0.4rem;
    border: 0px !important;
    font-size: 1.8em !important;
    background: none !important;
  }

  .nav-btns {
    display: block;
  }

  .nav-btns li span {
    text-align: left;
  }

  .skill-icons {
    display: block;
    margin-top: 0;
    padding: 20px 10px 0 10px;
  }

  .open-menu-true {
    top: calc(50% + 130px);
    height: calc(68vh - 60px);
  }

  .about-card {
    width: 90%;
    align-items: center;
    flex-direction: column;
  }

  .prj-card {
    width: 60vw;
    height: 60vh;
    text-align: center !important;
  }

  .prj-card h2 {
    font-size: 14px;
  }

  .prj-img-container {
    height: 75%;
  }

  .prj-img {
    max-width: 100%;
    width: 60vw;
    height: 7%;
    object-fit: cover;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
    position: absolute;
  }

  .contact-card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .contact-input-container {
    grid-template-columns: 1fr;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .portfolio-card {
    height: 99vh;
    width: 98vw;
  }

  .crt-piece {
    font-size: 14px;
  }

  .ln-loza {
    font-size: 5.5px;
    text-align: center;
  }

  .config-container {
    width: 93vw;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .retro-container {
    display: grid;
    color: #fff;
    justify-content: center;
    font-size: 12px;
    border: 6px white double;
    background-color: inherit;
    margin: 12px;
    grid-template-columns: 1fr auto 1fr;
  }

  .portfolio-section {
    width: 98vw;
  }

  .profile-description {
    padding: 0 5px;
  }

  .open-menu-false {
    top: calc(50% + 31px);
    height: calc(99vh - 60px);
  }

  .open-menu-true {
    top: calc(50% + 120px);
    height: calc(75vh - 60px);
  }

  .about-card {
    width: 90%;
    align-items: center;
    flex-direction: column;
  }

  .profile-dev {
    padding: 0px;
    transition: 1s;
    font-size: 25px;
    color: var(--dev-desc);
  }

  .scl-container {
    justify-content: center;
  }

  .about-me {
    margin: 10px 0px 0px 0px;
    text-align: center;
    vertical-align: top;
    padding-left: 0px;
    line-height: 1.8;
    transition: 1s;
  }

  .send-btn {
    position: relative;
    left: 60px;
  }

  .prj-card {
    width: 70vw;
    height: 55vh;
  }

  .prj-img-container {
    /* height: 200px; */
  }

  .prj-img {
    max-width: 100%;
    width: 70vw;
    height: 7%;
    object-fit: cover;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
    position: absolute;
  }

  .tui-fieldset {
    border: 6px var(--tuifield-fnb) double;
    padding: 12px;
    background-color: inherit;
    margin-bottom: 0px;
    display: block;
    min-inline-size: min-content;
  }

  .tui-fieldset legend {
    color: var(--tuifield-fnb);
  }

  .list-inline {
    max-width: 70vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .skill-icons {
    display: flex;
    justify-items: center;
    margin-top: 0;
    padding: 10px;
  }

  .logo-btn {
    color: var(--tuifield-fnb);
    opacity: 1;
  }

  .tui-cv {
    display: block;
    text-align: center;
  }

  .prj-stack-text {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .portfolio-card {
    top: 55%;
  }
}

@media screen and (max-width: 390px) and (orientation: portrait) {
  .retro-container {
  font-size: 10px;

  }
  .tui-radio input:checked ~ span::after {
    margin-left: 2px;
  }

  .tui-radio input:not(checked) ~ span::after {
    margin-left: 2px;
  }

  .retro-container-box-1 {
    padding: 4px;
  }

  .retro-container-box-2 {
    padding: 4px;
  }

  .retro-container-box-3 {
    padding: 4px;
  }

  .retro-container-box-4 {
    padding: 4px;
  }

  .retro-container-box-5 {
    padding: 4px;
  }

  .retro-container-box-6 {
    padding: 4px;
  }

  .retro-container-box-launch h2{
  font-size: 14px;
  }
}
